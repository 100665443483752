<template>
  <div class="box">
    <img :src="imgData" alt="" crossorigin="Anonymous" id="img2">
    <div class="mark-div" v-if="show_code_box"></div>
    <img alt="" crossorigin="Anonymous" id="img1" src="https://cdn.cailu88.com/jingxuanshi/canvas_qrcode_bg.jpg"
         style="position: absolute;top:-9999px;;">
    <canvas height="2480px" id="canvas2d" ref="canvas2d" style="position: absolute;top:-9999px;" width="1748"></canvas>
    <div ref="imageWrapper" :class="[show_code_box ?'slideup': !is_first ?'slidedown':'', 'code-box']">
      <img class="close" @click="close" src="https://cdn.cailu88.com/jingxuanshi/close%402x.png">
      <div ref="downLoad" class="box">
        <img class="code-bg" ref="code-bg" src="https://cdn.cailu88.com/jingxuanshi/qrcode_bg.jpg" alt="">
        <div class="qrcode-border">
          <div class="qrcode" id="qrcode" ref="qrcode"></div>
        </div>
      </div>
      <p class="tips">发送海报至微信后保存海报</p>
      <button class="share-btn" @click="share">保存海报</button>
      <loading :click="showLoading"></loading>
    </div>
  </div>
</template>
<script>
  import QRCode from 'qrcodejs2'
  import loading from './loading'

  export default {
    name: 'qrcode',
    props: {
      is_first: Boolean,
      show_code_box: Boolean
    },
    data () {
      return {
        file: '',
        imgData: '',
        text: '',
        showLoading: true
      }
    },
    components: {
      loading
    },
    created () {

    },
    mounted () {

    },
    methods: {
      base64ImgtoFile(dataurl, filename = 'file') {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let suffix = mime.split('/')[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime
        })
      },
      init(text) {
        var qrcode = new QRCode(this.$refs['qrcode'], {
          text: text, // 需要转换为二维码的内容
          width: 139,
          height: 139,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
          render: 'canvas'
        })
        this.$refs['qrcode'].getElementsByTagName('canvas')[0].style.width = 100 + '%';
        this.$refs['qrcode'].getElementsByTagName('img')[0].style.width = 100 + '%';
        setTimeout(() => {
          this.imgData = this.$refs.qrcode.getElementsByTagName('canvas')[0].toDataURL()
        }, 10)
      },
      close() {
        this.$emit('close')
        this.$refs.qrcode.innerHTML = ''
      },
      share() {
        if (!this.showLoading) {
          return false;
        }
        this.showLoading = false;
        var c = document.getElementById('canvas2d')
        var cxt = c.getContext('2d')
        setTimeout(()=>{
          var img = document.getElementById('img1')
          var img2 = document.getElementById('img2')
          img.crossOrigin = 'Anonymous'
          img2.crossOrigin = 'Anonymous'
          cxt.drawImage(img, 0, 0, 1748, 2480)
          cxt.drawImage(img2, 500, 1292, 750, 750)
          this.Oss.uploadFile(this.base64ImgtoFile(c.toDataURL('image/jpeg',0.5))).then(res => {
            this.showLoading = true;
            let event = 'share'
            let param = {
              type: 'image',
              imageUrl: res.url
            }
            this.$h5AppLink(event, param)
          }).catch(err=>{
            this.showLoading = true;
            alert('err')
          })
        }, 10)

      }
    },
  }
</script>
<style lang="less" scoped>
  .mark-div {
    width: 375px;
    height: 667px;
    background: #000000;
    opacity: 0.8;
    position: fixed;
    top:0;
    left: 0;
  }
  #img2 {
    position: absolute;
    top: -9999px;
  }
  .code-box {
    width: 100%;
    width: 375px;
    height: 532px;
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    background-color: white;
    position: fixed;
    bottom: -100%;
    left: 0;
    z-index: 2;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .box {
    margin-top: 35px;
  }

  .code-bg {
    width: 275px;
    height: 390px;
    margin: 0px 50px 0 50px;
  }

  .qrcode-border {
    width: 148px;
    height: 148px;
    background: #FFFFFF;
    border-radius: 12px;
    position: absolute;
    top:230px;
    left: 114px;
    box-sizing: border-box;
    padding:7px;
    display: flex;
    align-self: center;
    justify-content: center;
    .qrcode {
      width: 134px;
      height: 134px;
    }
  }

  .tips {
    height: 21px;
    font-size: 15px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    margin: 12px 0;
  }

  .share-btn {
    width: 300px;
    height: 50px;
    background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
    box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
    border-radius: 25px;
    margin: 0 auto;
    border:none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
  }

  @keyframes slidedown {
    from {
      bottom: 0;
    }

    to {
      bottom: -100%;
    }
  }

  .slidedown {
    bottom: -100%;
    animation: slidedown 0.5s linear;
  }

  .slideup {
    bottom: 0;
    animation: slideup 0.5s linear;
  }

  @keyframes slideup {
    from {
      bottom: -100%;
    }

    to {
      bottom: 0;
    }
  }

</style>
